//================================ Colors ================================
// Main colors
$color-main-one: #292929;
$color-main-two: #DA381B;
$color-main-three: #ebebeb;
$color-text : #ffffff;
$color-text-two: #6F6F70;
// Main computed colors
$color-main-one-dark: darken($color-main-one, 10);
// traffic colors
$color-traffic-disrupt: #FF0000;
$color-traffic-warning: #FF7A00;
$color-traffic-info: #0FA958;

// ================================ Fonts ================================

// Main font
$font-main-one: "comptom", "Source Sans Pro", "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;