
// ================================ Fonts ================================
$icomoon-ovveride-font-family: "override-ikisiofonticon" !default;
$icomoon-font-path-main: "../fonts" !default;
$icomoon-font-family: "ikisiofonticon" !default;

@font-face {
  font-family: '#{$icomoon-ovveride-font-family}';
  src:  url('#{$icomoon-font-path-main}/#{$icomoon-ovveride-font-family}.eot?u927j2');
  src:  url('#{$icomoon-font-path-main}/#{$icomoon-ovveride-font-family}.eot?u927j2#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path-main}/#{$icomoon-ovveride-font-family}.ttf?u927j2') format('truetype'),
  url('#{$icomoon-font-path-main}/#{$icomoon-ovveride-font-family}.woff?u927j2') format('woff'),
  url('#{$icomoon-font-path-main}/#{$icomoon-ovveride-font-family}.svg?u927j2##{$icomoon-ovveride-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


$lille-font-family: "comptom";
$lille-font-family-light: "Campton-Light" !default;
$lille-font-family-medium: "Campton-Medium" !default;
$lille-font-family-bold: "Campton-Bold" !default;

$lille-font-path-main: "../fonts/custom" !default;

// ================================ Fonts ================================

@font-face {
  font-family: '#{$lille-font-family}';
  font-weight: 300;
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-light}.eot?u927j2');
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-light}.eot?u927j2#iefix') format('embedded-opentype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.ttf?u927j2') format('truetype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.woff?u927j2') format('woff'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.svg?u927j2') format('svg');
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: '#{$lille-font-family}';
  font-weight: 400;
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-light}.eot?u927j2');
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-light}.eot?u927j2#iefix') format('embedded-opentype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.ttf?u927j2') format('truetype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.woff?u927j2') format('woff'),
  url('#{$lille-font-path-main}/#{$lille-font-family-light}.svg?u927j2') format('svg');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$lille-font-family}';
  font-weight: 600;
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-medium}.eot?u927j2');
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-medium}.eot?u927j2#iefix') format('embedded-opentype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-medium}.ttf?u927j2') format('truetype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-medium}.woff?u927j2') format('woff'),
  url('#{$lille-font-path-main}/#{$lille-font-family-medium}.svg?u927j2') format('svg');
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: '#{$lille-font-family}';
  font-weight: 700;
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-bold}.eot?u927j2');
  src:  url('#{$lille-font-path-main}/#{$lille-font-family-bold}.eot?u927j2#iefix') format('embedded-opentype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-bold}.ttf?u927j2') format('truetype'),
  url('#{$lille-font-path-main}/#{$lille-font-family-bold}.woff?u927j2') format('woff'),
  url('#{$lille-font-path-main}/#{$lille-font-family-bold}.svg?u927j2') format('svg');
  font-style: normal;
  font-display: block;
}

.ikisio {
  font-family: '#{$icomoon-ovveride-font-family}', '#{$icomoon-font-family}' !important;
}

